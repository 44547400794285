import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";

//components
import Accordion from "../components/Accordion";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/products/banner/2.jpg)`,
                        }}
                    >
                        <h1>Máquina de Zaranda</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Hay disponibles 3 tipos de tamizadoras que son trommel seco, cribador de disco y
                                removedor de polvo para diferentes materiales. Tienen la misma función para eliminar
                                impurezas como arenas, metales, piedras pequeñas, etiquetas en diferentes secciones de
                                lavado. El criba de disco es más Compacto que el trommel seco, es bueno colocarlo
                                después del desbarbado y el prelavado para separar las impurezas. Puede esparcir
                                uniformemente las botellas de PET en el siguiente proceso, como la cinta transportadora
                                y filtrar el agua residual. El trommel seco puede quitar las tapas y las arenas por
                                separado a través de Filtraciones de múltiples mallas.
                            </p>
                            <p style={{color: '#000'}}>
                                El removedor de polvo tiene dos especificaciones para elegir. Uno se llama separador en
                                zig-zag, el otro es separador de aspiración de etiquetas. Estas dos máquinas pueden
                                separar pequeñas etiquetas de plástico y películas aprovechando la gravedad y la
                                estructura de la máquina
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Caracteristicas</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                El dispositivo de tamizado para el reciclaje de PET separa los desechos sólidos como
                                arenas, metales, piedras pequeñas y etiquetas en la producción de reciclaje de botellas
                                de PET. El tamizado de botellas es útil para reducir la abrasión de funcionamiento del
                                equipo, la pérdida de producción para mejorar el rendimiento de la producción con ahorro
                                de agua. El tamizado de escamas de PET mejora el La calidad final de las escamas de PET
                                y estabiliza la calidad de las escamas ".
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                1500-6000 kg/h
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
